<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="600px"
      @keydown="closeModalAddDashboard"
      @click:outside="closeModalAddDashboard"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Añadir nuevo dashboard {{ titleSmartsheet }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="$v.form.name.$model"
                  :error="$v.form.name.$invalid && submitUpload"
                  clearable
                  label="Nombre del dashboard"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  v-model="$v.form.description.$model"
                  rows="3"
                  clearable
                  label="Descripción del dashboard"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="$v.form.url.$model"
                  :error="$v.form.url.$invalid && submitUpload"
                  clearable
                  label="Url"
                ></v-text-field>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalAddDashboard"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            color="blue darken-3"
            @click="handleSubmit"
            :disabled="loading"
            :loading="loading"
          >
            <small class="text-white">
              Añadir Dashboard
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { authUsuarioComputed } from '@/state/helpers'
import swal2 from 'sweetalert2'
export default {
  props: {
    titleSmartsheet: {
      type: String,
      required: true
    },
    typeSmartsheet: {
      type: String,
      required: true
    },
    modalAction: {
      type: Boolean,
      default: false
    },
    closeModalAddDashboard: {
      type: Function,
      required: true,
      default: () => {}
    }
  },
  validations: {
    form: {
      name: { required },
      url: { required },
      description: {}
    }
  },
  data() {
    return {
      loading: false,
      submitUpload: false,
      form: {
        name: null,
        url: '',
        description: ''
      }
    }
  },
  methods: {
    ...mapActions({
      addDashboard: 'smartsheet/addDashboard'
    }),
    resetForm() {
      this.form = {
        name: null,
        url: '',
        description: ''
      }
      this.submitUpload = false
    },
    async handleSubmit() {
      this.submitUpload = true
      if (!this.$v.form.$invalid) {
        this.loading = true
        const resp = await this.addDashboard({
          ...this.form,
          smartsheet_type: this.typeSmartsheet,
          user: this.user.email
        })
        if (resp.status === 200) {
          swal2.fire({
            icon: 'success',
            title: 'Excelente',
            text: 'Dashboard agregado correctamente',
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true
          })
          this.$emit('dashboardAdded')
          this.closeModalAddDashboard()
          this.resetForm()
        }
      }
      this.loading = false
    }
  },
  computed: {
    ...authUsuarioComputed
  }
}
</script>

<style></style>
